import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import "vuetify/dist/vuetify.min.css";
import colors from "vuetify/es5/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#4299E2",
        secondary: "#44C8F5",
        accent: "#1A237E",
        error: "#b71c1c",
        info: "#90A4AE",
        warning: colors.amber.base,
        success: colors.green.accent3
      }
    }
  }
});
