<template>
  <v-app>
    <AppBar/>
    <Drawer />
    <Jumbotron />

    <slot />

    <v-footer padless color="accent">
      <v-row justify="center" no-gutters>
      </v-row>
      <v-col class="py-4 text-center white--text" cols="12">
        {{ new Date().getFullYear() }} — <strong>AJP y Eventos. Caracas, Venezuela</strong>
      </v-col>
    </v-footer>

  </v-app>
</template>

<script>
export default {
  name: 'default',
  components: {
    AppBar: () => import('@/components/AppBar'),
    Drawer: () => import('@/components/Drawer'),
    Jumbotron: () => import('@/components/Jumbotron'),

  },
  data () {
    return {
      title: 'AJP y Eventos',
    }
  },
}
</script>
<style lang="sass">

</style>
