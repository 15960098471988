import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: null,
    loaded: false
  },
  mutations: {
    setDrawer: (state, payload) => {
      state.drawer = payload
    },
    toggleDrawer: (state) => {
      state.drawer = !state.drawer
    },
    setLoaded: (state, payload) => {
      state.loaded = payload
    }
  },
  actions: {
    updateActionValue({ commit }) {
      commit('updateValue', payload)
    }
  },
  getters: {
    getterDrawer: state => {
      return state.drawer
    },
    getterLoaded: state => {
      return state.loaded
    }
  }
})
