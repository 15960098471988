import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import DefaultLayout from './layouts/Default.vue'
import vuetify from './plugins/vuetify';
import './plugins/components'

Vue.component('Layout', DefaultLayout)

var firebase = require("firebase/app");
// require("firebase/auth");
// require("firebase/storage");
require("firebase/database");
require("firebase/firestore");
require("firebase/functions");
import firebaseConfig from "@/plugins/firebase";
firebase.initializeApp(firebaseConfig);

export const db        = firebase.firestore();
export const functions = firebase.functions();
// export const storage   = firebase.storage();
// export const auth      = firebase.auth();


Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
