import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

  const routes = [
    {
      path: "/about",
      component: () => import('@/views/About' /* webpackChunkName: "pages/about" */),
			name: "about",

    },
    {
      path: "/certificate",
      component: () => import('@/views/Certificate' /* webpackChunkName: "pages/certificate" */),
      name: "certificate"
    }, {
      path: "/contact",
      component: () => import('@/views/Contact' /* webpackChunkName: "pages/contact" */),
			name: "contact",

    },

    {
      path: "/eventos",
      component: () => import('@/views/Events.vue' /* webpackChunkName: "pages/eventos" */),
			name: "eventos",

    },
    {
      path: "/event",
      component: () => import('@/views/event/index.vue' /* webpackChunkName: "pages/event/index" */),
			name: "event",

    },
    {
      path: "/login-operador/:evento_id",
      component: () => import('@/views/LoginOperator.vue' /* webpackChunkName: "pages/event/index" */),
			name: "loginOperator",

    },
    {
      path: "/event/register/:event_id?",
      component: () => import('@/views/event/register/event_id.vue' /* webpackChunkName: "pages/event/register/_event_id" */),
			name: "event-register-event_id",

    },
    {
      path: "/event/express/:event_id?",
      component: () => import('@/views/event/express.vue' /* webpackChunkName: "pages/event/register/_event_id" */),
			name: "event-register-event_id",
    },
    {
      path: "/event/ticket/:register_id?",
      component: () => import('@/views/event/ticket.vue' /* webpackChunkName: "pages/event/register/_event_id" */),
			name: "ticket-register-id",
    },
    {
      path: "/event/:id",
      component: () => import('@/views/event/id.vue' /* webpackChunkName: "pages/event/_id" */),
			name: "event-id",

    },
    {
      path: "/",
      component: () => import('@/views/Index' /* webpackChunkName: "index" */),
			name: "index",
    },
    {
      path: '*',
      component: () => import('@/views/NotFound' /* webpackChunkName: "pages/not-found" */),
			name: "NotFound",

    },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
